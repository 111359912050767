.accounts-snapshot,
.leads-snapshot {
  &-main {
    display: flex;
    text-align: left;
    background-image: linear-gradient(180deg, #fbfbfb 80%, #f4f4f4 100%);
    border: 1px solid #d0d0d0;
    border-radius: 10px;
  }

  &-item {
    flex: 1;
    padding: 20px 40px;
    border-right: 1px solid #d0d0d0;

    &:last-child {
      border-right: none;
    }

    &-heading,
    &-subheading {
      color: #989898;
      font-weight: 600;
      font-size: 14px;
    }

    &-subheading {
      font-size: 11px;
    }

    &-value {
      font-size: 28px;
      font-weight: 500;
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
}
