.leads {
  &-main {
    padding: 100px 50px;
    text-align: left;
  }

  &-heading {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  &-subheading {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 50px;
  }

  &-table-main {
    margin-top: 50px;
  }

  &-no-results {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
  }
}
