@font-face {
  font-family: "Neurial Grotesk";
  font-weight: 400;
  src: url("./assets/fonts/Neurial_Grotesk/NeurialGrotesk-Light.otf")
    format("opentype");
}

@font-face {
  font-family: "Neurial Grotesk";
  font-weight: 500;
  src: url("./assets/fonts/Neurial_Grotesk/NeurialGrotesk-Regular.otf")
    format("opentype");
}

@font-face {
  font-family: "Neurial Grotesk";
  font-weight: 600;
  src: url("./assets/fonts/Neurial_Grotesk/NeurialGrotesk-Medium.otf")
    format("opentype");
}

@font-face {
  font-family: "Neurial Grotesk";
  font-weight: 700;
  src: url("./assets/fonts/Neurial_Grotesk/NeurialGrotesk-Bold.otf")
    format("opentype");
}

body {
  margin: 0;
  font-family: "Neurial Grotesk", "Inter", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
