.lead-details {
  padding: 20px 40px;
  width: 1080px;
  min-width: 600px;

  &-button {
    cursor: pointer;
  }

  &-card {
    display: flex;
    font-size: 14px;
    line-height: 31px;
    &-left {
      padding-right: 20px;
      font-weight: 600;
      width: 150px !important;
    }
    &-right {
      flex: 1;
      > span,
      > a {
        display: inline-block;
        line-height: 17px;
        width: 450px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  &-heading {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 40px;

    &-main {
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &-code {
    max-width: 100%;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-wrap: break-word;
    font-size: 12px;
  }
}
