@import "../../global/GlobalStyles.scss";

.leftnav {
  background-color: $global-left-nav-bg-color;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;

  &-logo {
    display: block;
    padding-top: 40px;
    padding-bottom: 60px;
    border-bottom: solid $global-divider-line-color 1px;
  }

  &-greeting {
    margin-top: 40px;
    text-align: left;
    font-weight: 500;
    font-size: 18px;
  }

  &-items {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;
    flex-grow: 1;
    padding: 40px 0px;

    &-top {
      flex-grow: 1;
      align-items: flex-start;
    }

    &-bottom {
      max-height: 200px;
      flex-grow: 0;

      &-border {
        border-bottom: solid $global-divider-line-color 1px;
      }
    }
  }

  &-item {
    text-align: left;
    height: 50px;
    line-height: 50px;
    padding-left: 10px;
    font-size: 15px;
    cursor: pointer;
    border-left: solid transparent 2px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;

    &-active {
      background-color: #e8eeff;
      border-left: solid blue 2px;
    }

    &:hover {
      background-color: #e8eeff;
    }
  }
}
