$text-color-secondary: #989898;
$global-grey: #fafafa;
$global-grey-line-color: #dbe3ea;
$global-left-nav-bg-color: #f6f8fa;
$global-divider-line-color: #dbe3ea;

.slack-button {
  align-items: center;
  color: #000;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-flex;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  justify-content: center;
  text-decoration: none;
  width: 236px;
}
