@import "../../global/GlobalStyles.scss";

.accounts {
  &-top-area {
    border-bottom: 1px solid $global-divider-line-color;
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
  &-main {
    padding: 40px 50px 20px;
    text-align: left;
  }

  &-heading {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  &-subheading {
    font-size: 14px;
    font-weight: 600;
    color: #989898;
  }

  &-table-main {
    margin-top: 50px;
  }

  &-no-results {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
  }
}
