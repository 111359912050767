@import "../global/GlobalStyles.scss";

.dashboard {
  display: flex;
  height: calc(100vh - 40px);
  background-color: $global-left-nav-bg-color;
  padding: 20px;
  padding-left: 0px;

  &-left {
    min-width: 200px;
    max-height: calc(100vh - 40px);
  }

  &-right {
    flex-grow: 1;
    max-height: calc(100vh - 40px);
    overflow: scroll;
    background-color: #fff;
    border-radius: 10px;
  }
}
