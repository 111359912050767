@import "../../global/GlobalStyles.scss";

.table-cell {
  &-lead {
    &-name {
    }

    &-email {
      color: $text-color-secondary;
      font-size: 12px;
    }
  }

  &-account {
    display: flex;
    align-items: center;
    column-gap: 12px;
    font-size: 14px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: 220px;
    &-logo {
      border-radius: 5px;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      &-image {
        max-width: 30px;
        border-radius: 5px;
      }
    }
    &-link {
      color: inherit;
      cursor: pointer;
      text-decoration: none;
      font-size: 12px;
      &:hover {
        text-decoration: underline;
      }
    }

    &-url {
      color: $text-color-secondary;
    }
  }

  &-a-details {
    &-item {
      display: flex;
      align-items: center;
      column-gap: 8px;
      line-height: 16px;
      font-size: 12px;
      &-icon {
        font-size: 16px;
        vertical-align: middle;
      }
    }
  }

  &-channels {
    display: flex;
    column-gap: 10px;
    row-gap: 5px;
    flex-wrap: wrap;
  }

  &-intent {
    &-score {
      width: 24px;
      line-height: 24px;
      border-radius: 50%;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: white;
    }
  }

  &-action {
    &-button {
      cursor: pointer;
      padding: 5px 10px;
      border: 1px solid #d2d2d2;
      border-radius: 5px;
      background-color: #ebebeb;

      &:hover {
        background-color: #d2d2d2;
      }
    }
  }
}
