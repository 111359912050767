@import "../../global/GlobalStyles.scss";
.acc-details {
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;

  &-back {
    display: inline-block;
    cursor: pointer;
    margin-bottom: 30px;
  }

  &-name {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &-stats {
    margin-right: 40px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-link {
      cursor: pointer;
      text-decoration: none;
      color: "inherit";
      padding-right: 10px;

      &:hover {
        filter: brightness(0.8);
      }
    }

    &-item {
      font-weight: 500;
      line-height: 24px;

      &-heading {
        font-weight: 600;
        padding: 8px;
      }
    }
  }
  &-info {
    padding: 40px 20px;
    border-left: 1px solid $global-grey-line-color;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;

    &-heading {
      font-weight: 600;
    }
  }

  &-heading {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 20px;
  }

  &-table {
    border: 1px solid $global-grey-line-color;
    border-radius: 10px;
    margin-bottom: 80px;

    &-header {
      background-color: $global-grey;
    }
  }

  &-leadCircle {
    border-radius: 50%;
    padding: 8px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    margin-right: 8px;
    color: #fff;
    background-color: #000;
  }
}
