.filter-pop {
  padding: 30px;
  background-color: #fafafa;
  min-width: 600px;
  max-height: 600px;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 30px;
  }

  &-left {
    padding-top: 20px;
    border-right: solid 1px #d0d0d0;
    min-height: 500px;
    max-height: 500px;

    &-item {
      font-size: 14px;
      cursor: pointer;
      display: flex;
      column-gap: 10px;
      align-items: center;
      padding-bottom: 20px;
      &:hover {
        font-weight: 600;
      }
    }
  }

  &-right {
    padding-top: 20px;
    padding-left: 20px;
    flex: 1;
    max-height: 500px;
    overflow-y: auto;
  }
}
