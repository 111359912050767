@import "../../global/GlobalStyles.scss";

.card-custom {
  background-color: $global-grey;
  border-radius: none;
  padding: 40px;

  &-heading {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 32px;
  }
}
