.a-table {
  margin-top: 60px;
  &-filters {
    margin-bottom: 20px;
    column-gap: 20px;
    display: flex;
    align-items: center;
  }

  &-data-grid {
    border: none !important;

    &-cell {
      font-size: 12px;

      &-fav {
        display: none;
        cursor: pointer;
        font-size: 12px;

        &-show {
          display: block;
          cursor: pointer;
          font-size: 12px;
        }
      }

      &-action {
        font-size: 12px;
        display: flex;
        padding: 10px 5px;
        border: 1px solid black;
        cursor: pointer;

        &:hover {
          background-color: rgb(87, 87, 87);
          color: white;
        }

        &-done {
          font-size: 12px;
          display: flex;
          padding: 10px 5px;
          border: 1px solid black;
          background-color: rgb(87, 87, 87);
          color: white;
        }
      }
    }
  }
}
