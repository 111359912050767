.settings {
  &-main {
    padding: 100px 50px;
    text-align: left;
  }

  &-heading {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 50px;
  }

  &-slack-connected {
    font-weight: 500;
    margin-bottom: 20px;

    &-status {
      color: #00b159;
      font-weight: 600;
    }

    &-status-no {
      color: #ff0000;
      font-weight: 600;
    }
  }
}
